import React from 'react'
import { Macros } from '../macros'
import {
  linkParser,
  nodeParser,
} from '@dustin-web/content-components/src/macro-content/node-parsers'
import parse, { Element } from 'html-react-parser'
import { nodeParserOptions } from '@dustin-web/content-components/src/macro-content/options'
import { log } from '@dustin-web/microsite-config/src/logger'

type Props = {
  html: string
}

export const HtmlBlock = ({ html }: Props) => {
  let htmlComponents: ReturnType<typeof parse> = ''

  try {
    htmlComponents = parse(html, {
      ...nodeParserOptions,
      replace: domnode => nodeParser(domnode as Element, Macros, [linkParser]) ?? domnode,
    })
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err)
    log.warn(`Invalid HTML content on content page`)
  }

  return <div>{htmlComponents}</div>
}
